/*
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2021-10-29 13:58:43
 * @LastEditors: liyunfei12354 3090384495@qq.com
 * @LastEditTime: 2024-12-21 15:14:02
 */
import zhLocale from 'element-ui/lib/locale/lang/zh-TW'
import agrEn from './agr-en'
export default {
    // 登录页面
    login: {
        loginAndRegister: '註冊/登錄',
        registerAndLogin: '登錄/註冊',
        userLogin: '用戶登錄',
        title: '手機帳號和郵箱帳號互相獨立，請注意您的帳號類型。',
        mobileLogin: '手機',
        emailLogin: '郵箱',
        email: 'E-mail',
        loginBtn: '登錄',
        autoLogin: '下次自動登錄',
        retransmission: '秒後重發',
        formList: [
            {
                label: '請輸入手機號碼',
                placeholder: '獲取驗證碼',
            },
            {
                label: '驗證碼',
                placeholder: '請輸入驗證碼',
            },
            {
                label: '請輸入郵箱',
            },
        ],
        btn: {
            submit: '創建新帳號',
            title: '真實的資料非常重要，請仔細填寫',
        },
        rule: {
            phone: {
                requiredMsg: '手機號格式不正確',
                patternMsg: '手機號未註冊，請創建新帳號',
            },
            authCode: {
                requiredMsg: '驗證碼錯誤，請重新填寫',
            },
            email: {
                requiredMsg: '郵箱格式不正確',
                patternMsg: '郵箱未註冊，請創建新帳號',
            },
        },
        codeSuccess: '驗證碼已發送，請查收',
        success: '發送成功',
        error: '發送失敗',
        loginSuccess: '登錄成功',
        account: {
            accountTitle: '登錄',
            registerTitle: '註冊',
            resetTitle: '設置密碼',
            password: '密碼',
            placeholder: '請輸入密碼',
            codeLogin: '驗證碼登入',
            accountLogin: '使用密碼登入',
            forgotPassword: '忘記密碼？',
            registerTips:[
                '還沒有帳戶？', 
                '註冊', 
                '帳戶已存在'
            ],
            checkRule: [
                '8-20 個字符',
                '至少包含一個大寫字母',
                '至少包含一個小寫字母',
                '至少包含一個數字', 
                '密碼不一致'
            ],
            errorTips: [
                '密碼錯誤', 
                '登入失敗', 
                '帳戶不存在'
            ],
            reset:[
                '重置密碼', 
                "下一步",
                '新密碼不能與上一次的密碼相同', 
                '跳過',
                '密碼設置成功'
            ],
            setPasswordTips:'密碼由 8 至 20 個字元組成，必須包含大寫字母、小寫字母及數字。',
            confirmPassword: "確認密碼",
        }
    },
    // 注册页面
    register: {
        mobileRegister: '手機註冊',
        emailRegister: '郵箱註冊',
        enroll: '手機號已註冊',
        enrollEmail: '郵箱已註冊',
        search: '搜索',
        title: '手機帳號和郵箱帳號互相獨立，請注意您的帳號類型。',
        needRead: {
            agree: '我已閱讀並同意',
            privacy: '《用戶隱私協議》',
        },
        register: '註冊',
        check: '勾選“用戶隱私協議”',
        newUser: '新用戶註冊',
        form: {
            labelList: ['姓名', '* 性别', '* 身高', '* 年齡'],
        },
        placeholder: ['請輸入姓名', '請輸入你的身高', '請輸入你的年齡'],
        unit: ['公制', '英制', '歲'],
        sexOptions: {
            male: '男',
            female: '女',
        },
        btn: '提交',
        rules: {
            metricheight: ['允許測量的身高範圍是 2ft3in-6ft7in，請重新輸入'],
            heightMsg: ['允許測量的身高範圍是 70-200cm，請重新輸入'],
            ageMsg: ['允許測量的年齡範圍 10-99歲，請重新輸入'],
            agePointMsg: ['填寫了非整數：年齡只能為整數，請填寫正確的年齡'],
        },
        success: '註冊成功',
        userMessage: '請輸入用戶名',
        verify: '請輸入4位數的驗證碼',
        errorMsg: '允許輸入的身高格式不支持小數，請重新輸入',
        abnormalMsg: '註冊異常',
    },
    // 模型合成
    model: {
        loading: '模型生成中，請您稍候',
        modelLoading: '模型加載中...',
        tabPane: [
            {
                title: '深度實感技術',
                msg: '通過三維掃描準確採集身體局部細節，保證圍度測量的毫米級精度，讓健身後的體型變化清晰呈現。',
            },
            {
                title: 'BDA 體成分演算法',
                msg: '維塑採用最先端的人體成分測算體系--BDA 演算法確定人體成分，這項基於身體體積的測算方式將更精確地評估肥胖帶來的健康風險。',
            },
            {
                title: '誤差產生因素',
                msg: '寬鬆的著裝、身上的異物等都會導致不同程度的誤差，如您想獲取最精確的數據，請您穿著緊身衣或減少身上的衣物。',
            },
            {
                title: '報告入口',
                msg: '建議收藏網頁，方便日後查看報告。稍後您也可將此報告發送至郵箱保存。',
            },
        ],
        collect: ['請收藏本網頁，', '方便日後查看報告。'],
        know: '我知道了',
        reminder: [
            '溫馨提示',
            '您的三維模型已生成，可嘗試左右滑動來旋轉模型。',
            '您的報告已生成，可點擊“查看報告”按鈕查看。',
            '您的體態評估未成功，請查看體成分報告。',
            '您的身體成分未成功，請查看體態評估報告。',
            '您的測量未成功，請到設備端重新體驗哦~',
            '身體成分未成功，請到設備端重新體驗哦~',
            '體態評估未成功，請到設備端重新體驗哦~',
        ],
        btn: {
            viewReport: '查看報告',
            bodyReport: '查看體成分報告',
            postureReport: '查看體態報告',
            lastReport: '查看上次報告',
            loading: '加載中...',
        },
    },
    // 报告
    report: {
        btn: {
            download: '下載報告',
            send: '發送報告',
            report: '報告',
        },
        reportSuccess: '報告生成成功',
        tabPane: {
            labelList: ['測量時間', '測量項目', '身體成分', '體態評估', '肩部功能'],
        },
        sendEmail: '發送至郵箱:',
        requiredMsg: '請輸入你的郵箱地址',
        patternMsg: '請輸入正確的郵箱',
        send: '發送',
        download: '下載報告',
        sendReport: '發送報告',
    },
    // 侧边栏
    sidebar: {
        successMsg: {
            sex: '性別修改成功',
            height: '身高修改成功',
            age: '年齡修改成功',
        },
        logOut: '退出登錄',
        personal: '個人中心',
        cut: '切換單位/語言',
        changeSort: '切換產品報告',
        setPassword: '設置/修改密碼',
        describe: '* 切換產品型號後，將為您展示對應產品型號下的所有測量報告。',
        describeBtn: '產品型號切換後，將為您跳轉到該產品型號下的最新測量報告。',
        descReport: '產品報告切換成功',
        unit: ['公制 (kg, cm)', '英制 (ft, in, lb)'],
        read: {
            reading: '閱讀',
            privacy: '《用戶隱私協議》',
        },
        setting: '設置',
        form: {
            labelList: ['手機號碼', '單位', '語言', '昵稱', '性別', '身高', '年齡'],
        },
        placeholder: ['修改昵稱', '性別修改', '身高修改', '年齡修改'],
        btn: {
            cancelBtn: '取消',
            accomplishBtn: '完成',
            confirmBtn: '確定',
        },
        dialog: [
            '性別是體成分測量的重要依據之一，修改後測量的數據會與以往數據出現差異，請確認是否修改？',
            '身高是體成分測量的重要依據之一，修改後測量的數據會與以往數據出現差異，請確認是否修改？',
            '年齡是體成分測量的重要依據之一，修改後測量的數據會與以往數據出現差異，請確認是否修改？',
        ],
        rules: {
            metricheight: ['請填寫範圍內身高（2ft3in-6ft7in）'],
            heightMsg: ['請填寫範圍內身高（70-200cm）'],
            ageMsg: ['請填寫範圍內年齡（10-99歲）', '填寫了非整數：年齡只能為整數，請填寫正確的年齡'],
        },
        heightMsg: '請重新選擇身高',
        ageMsg: '請重新選擇年齡',
        ModelBinding: '好像有人捷足先登掃碼了呢，請重新測量吧！',
        ReportEmpty: {
            title: '您還沒有相關檢測報告',
            desc: '找到 Visbody 3D 體測精靈，進行一次測量吧！',
        },
    },
    // 无测量报告
    modelBinding: {
        desc: '好像有人捷足先登掃碼了呢，請重新測量吧！',
    },
    // 扫码模型已被绑定
    reportEmpty: {
        title: '您還沒有相關檢測報告',
        desc: '找到 Visbody 3D 體測精靈，進行一次測量吧!',
        descS30: '找到 Visbody 3D 體測精靈，進行一次測量吧!',
        descM30: '找到 Visbody 3D 體測精靈，進行一次測量吧!',
    },
    // 用户信息
    userInfo: {
        height: '身高:',
        age: '年齡:',
        unit: '歲',
        weight: '體重',
    },
    // 体成分
    mass: {
        title: '體成分評估總覽',
        titles: '身體成分',
        springFrame: '',
        massFrame: '暫無當天測量測量數據',
        contrast: '選一份報告對比',
        contrastReport: '選擇一份對比報告',
        noRecord: '無記錄',
        score: '分',
        status: '您目前的體成分狀況',
        WT: '體重',
        PBF: '體脂率',
        endSymbol: '。',
        // 测量相关
        measure: {
            value: '測量值',
            standardRange: '標準範圍',
            comparedWithLastLime: '對比上次',
            comparedNet: '對比上次',
            current: '本次测量分数',
            WT: '體重',
            FFM: '去脂體重',
            BFM: '體脂肪',
            LM: '肌肉量',
            TBW: '總水分',
            SM: '骨骼肌',
            PROTEIN: '蛋白質',
            TM: '無機鹽',
            BMR: '基礎代謝',
            WHR: '腰臀比',
            BMI: 'BMI',
            PBF: '體脂率',
            VFG: '內臟脂肪等級',
            unit: 'kg',
            BMRUnit: 'kcal/d',
        },
        // 定义解读
        explain: [
            {
                title: '體重',
                msg: '體重是身體水分、蛋白質、無機鹽和體脂肪的總和。',
            },
            {
                title: '去脂體重',
                msg: '去脂體重是體重扣除脂肪的部分。',
            },
            {
                title: '體脂肪',
                msg: '體脂肪是皮下脂肪，內臟脂肪和肌肉之間脂肪的總和。',
            },
            {
                title: '肌肉量',
                msg: '肌肉量是人體的瘦組織群，包括了骨骼肌、平滑肌和心肌。',
            },
            {
                title: '總水分',
                msg: '身體水分在人體成分中占的含量最多，占體重的50%~70%，身體水分分佈於人體細胞和體液中，其中大部分存在於肌肉細胞中。',
            },
            {
                title: '骨骼肌',
                msg: '骨骼肌又稱橫紋肌，附著在骨骼上的肌肉，肌肉中的一種，此處計算的是骨骼肌的含量。',
            },
            {
                title: '蛋白質',
                msg: '蛋白質是含氮的固態物質，存在於人體所有細胞內，參與細胞構成。是肌肉量的主要成分。',
            },
            {
                title: '無機鹽',
                msg: '人體是由有機物、無機物和水組成的，人體裏的無機物叫無機鹽，無機鹽大約占人體重量的5%。',
            },
            {
                title: '基礎代謝',
                msg: '基礎代謝指人體在清醒而安靜的狀況下，不受運動、食物、神經緊張、外界溫度變化等影響時一天消耗的總能量。',
            },
            {
                title: '腰臀比',
                msg: '腰臀比是腰圍和臀圍的比值，是判定中央肥胖的重要指標。',
            },
            {
                title: 'BMI',
                msg: 'BMI主要用於評估外觀肥胖度，國際上常作為衡量人體胖瘦程度的標準。',
            },
            {
                title: '體脂率',
                msg: '體脂肪率是指體脂肪占體重的比率。',
            },
            {
                title: '內臟脂肪等級',
                msg: '內臟脂肪等級是評價是否屬於隱性肥胖的重要指標。',
            },
            {
                title: '細胞內液',
                msg: '細胞內液：是肌體細胞總體所含的體液，是原生質的基本組成部分。',
            },
            {
                title: '細胞外液',
                msg: '細胞外液：通常指為與細胞外的體液，包括血漿以及介於血管和組織細胞之間的組織間液。',
            },
            {
                title: '代謝年齡',
                msg: '代謝年齡是指身體及其健康相關的代謝功能的表現。',
            },
        ],
        // 节段
        segment: {
            fat: '節段脂肪',
            muscle: '節段肌肉',
            right: '右',
            left: '左',
            standard: '標準',
            lowStandard: '低標準',
            superStandard: '高標準',
            peel: '(皮重)',
        },
    },
    // 体态
    shape: {
        title: '體態評估總覽',
        titles: '體態評估',
        status: '您目前的體態狀況',
        suggest: '建議',
        possibility: '，存在',
        models: {
            front: '正面',
            left: '左側面',
            right: '右側面',
            top: '背面',
            low: '偏低',
            high: '偏高',
            normal: '正常',
        },
        item: {
            deviate: '測量值',
            result: '說明',
            normal: '正常',
			keAbnormal: '可能異常',
            abnormal: '異常',
            head: '頭前引',
            headSlant: '頭側歪',
            roundShoulderLeft: '左圓肩',
            roundShoulderRight: '右圓肩',
            highLowShoudler: '高低肩',
            pelvis: '骨盆前/後移',
            leftKneeCheck: '左膝評估',
            rightKneeCheck: '右膝評估',
            leg: '腿型',
            leftLeg: '左腿：',
            rightLeg: '右腿：',
        },
        // 定义解读
        explain: [
            {
                title: '頭前引',
                msg: '左側面耳朵點與脖子中心連線和側面中位線的夾角',
            },
            {
                title: '頭側歪',
                msg: '正面頭部中點與脖子中心連線和正面中位線的夾角',
            },
            {
                title: '左圓肩',
                msg: '背部左側最高點連線和肩部切線的夾角',
            },
            {
                title: '右圓肩',
                msg: '背部右側最高點連線和肩部切線的夾角',
            },
            {
                title: '高低肩',
                msg: '背面左右肩峰點垂直方向的距離',
            },
            {
                title: '骨盆前/後移',
                msg: '左側面脖子中心點、髖骨點、腳踝三點連線的夾角',
            },
            {
                title: '左膝評估',
                msg: '左側面髖骨點、膝關節、腳踝點三點連線的夾角',
            },
            {
                title: '右膝評估',
                msg: '右側面髖骨點、膝關節、腳踝點三點連線的夾角',
            },
            {
                title: '腿型',
                msg: '左右腿正面髖骨骼點、膝關節、腳踝點連線的夾角',
            },
        ],
        exception: [
            {
                title: '偏左',
                abnormal: '',
            },
            {
                title: '偏右',
                abnormal: '',
            },
            {
                title: '左高',
                abnormal: '',
            },
            {
                title: '右高',
                abnormal: '',
            },
            {
                title: '骨盆後移',
                abnormal: '',
            },
            {
                title: '骨盆前移',
                abnormal: '',
            },
            {
                title: '左膝超伸',
                abnormal: '',
            },
            {
                title: '左膝前曲',
                abnormal: '',
            },
            {
                title: '右膝超伸',
                abnormal: '',
            },
            {
                title: '右膝前曲',
                abnormal: '',
            },
            {
                title: 'K型腿',
                abnormal: '',
            },
            {
                title: 'D型腿',
                abnormal: '',
            },
            {
                title: 'X型腿',
                abnormal: '',
            },
            {
                title: 'O型腿',
                abnormal: '',
            },
        ],
    },
    newshape: {
        explain: [
            {
                msg: '耳朵與人體重力線之間的垂直距離',
            },
            {
                msg: '左右耳朵的連線與水平線之間的角度',
            },
            {
                msg: '左肩峰位置與重力線的垂直距離',
            },
            {
                msg: '右肩峰位置與重力線的垂直距離',
            },
            {
                msg: '兩側頸根與肩峰連線的差值',
            },
            {
                msg: '髖關節與人體重力線之間的垂直距離',
            },
            {
                msg: '矢狀面上，踝關節、左膝關節下1厘米和髖關節的夾角',
            },
            {
                msg: '矢狀面上，踝關節、右膝關節下1厘米和髖關節的夾角',
            },
            {
                msg: '冠狀面上，左右髖關節、膝關節以及踝關節之間的夾角',
            },
        ],
        item: {
            pelvis: '骨盆前移',
        },
    },
    // 围度信息
    girth: {
        title: '體圍',
        present: '(本次)',
        noHistory: '無數據',
        tpl: [
            {
                title: '頸圍',
                key: 'neckGirth',
            },
            {
                title: '左上臂圍',
                key: 'leftUpperArmGirth',
            },
            {
                title: '右上臂圍',
                key: 'rightUpperArmGirth',
            },
            {
                title: '胸圍',
                key: 'bustGirth',
            },
            {
                title: '高腰圍',
                key: 'waistGirth',
            },
            {
                title: '中腰圍',
                key: 'midWaistGirth',
            },
            {
                title: '臀圍',
                key: 'hipGirth',
            },
            {
                title: '左大腿圍',
                key: 'leftThighGirth',
            },
            {
                title: '左大腿中部圍',
                key: 'leftMidThighGirth',
            },
            {
                title: '左大腿最小圍',
                key: 'leftMinThighGirth',
            },
            {
                title: '右大腿圍',
                key: 'rightThighGirth',
            },
            {
                title: '右大腿中部圍',
                key: 'rightMidThighGirth',
            },
            {
                title: '右大腿最小圍',
                key: 'rightMinThighGirth',
            },
            {
                title: '左小腿圍',
                key: 'leftCalfGirth',
            },
            {
                title: '右小腿圍',
                key: 'rightCalfGirth',
            },
            {
                title: '低腰圍',
                key: 'lowWaistGirth',
            },
        ],
    },
    // 肩部
    shoulder: {
        title: '肩部功能',
        model: {
            val: '測量值',
            scope: '最大活動位置',
            diff: '對比上次',
        },
        normal: '正常',
        conclusion: '結論',
        analyse: '分析: ',
        suggest: '建議: ',
        cause: '具體原因請找專業人士做進一步篩查。',
    },
    reportTips: {
        title: '如需刪除報告，請聯繫設備管理員。',
        btnMsg: 'OK',
        alaryTips: '該測量報告已被管理員刪除，即將跳轉下一份報告！',
    },
    saveQr: {
        title: 'Visbody 3D體測報告',
        btnMsg: '長按圖片保存到手機',
        tips: '如發現無法保存，請去【設置】打開相應許可權',
    },
    share: {
        title: '請複製下方的鏈接後分享',
        btnMsg: '(複製)',
    },
    ...zhLocale,
    ...agrEn,
}
