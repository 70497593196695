/*
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2021-10-29 13:58:43
 * @LastEditors: liyunfei12354 3090384495@qq.com
 * @LastEditTime: 2024-12-24 18:49:27
 */
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import agrZh from './agr-zh'
export default {
    // 登录页面
    login: {
        loginAndRegister: '注册/登录',
        registerAndLogin: '登录/注册',
        userLogin: '用户登录',
        title: '手机账号和邮箱账号互相独立，请注意您的账号类型。',
        mobileLogin: '手机',
        emailLogin: '邮箱',
        email: 'E-mail',
        loginBtn: '登录',
        autoLogin: '下次自动登录',
        retransmission: '秒后重发',
        formList: [
            {
                label: '请输入手机号码',
                placeholder: '获取验证码',
            },
            {
                label: '验证码',
                placeholder: '请输入验证码',
            },
            {
                label: '请输入邮箱',
            },
        ],
        btn: {
            submit: '创建新账号',
            title: '真实的信息非常重要，请仔细填写',
        },
        rule: {
            phone: {
                requiredMsg: '手机号格式不正确',
                patternMsg: '手机号未注册，请创建新账号',
            },
            authCode: {
                requiredMsg: '验证码错误，请重新填写',
            },
            email: {
                requiredMsg: '邮箱格式不正确',
                patternMsg: '邮箱未注册，请创建新账号',
            },
        },
        codeSuccess: '验证码已发送，请查收',
        success: '发送成功',
        error: '发送失败',
        loginSuccess: '登录成功',
        account: {
            accountTitle: '登录',
            registerTitle: '注册',
            resetTitle: '设置密码',
            password: '密码',
            placeholder: '请输入密码',
            codeLogin: '验证码登录',
            accountLogin: '使用密码登录',
            forgotPassword: '忘记密码?',
            registerTips:[
                '还没有账号?', 
                '注册', 
                '账号已存在'
            ],
            checkRule: [
                '8-20 个字符',
                '至少包含一个大写字母',
                '至少包含一个小写字母',
                '至少包含一个数字', 
                '密码不一致'
            ],
            errorTips: [
                '密码错误', 
                '登录失败', 
                '账号不存在'
            ],
            reset:[
                '重置密码', 
                "下一步",
                '新密码不能与上一次的密码相同', 
                '跳过',
                '密码设置成功'
            ],
            setPasswordTips:'密码由 8-20 个字符组成，须包含大写字母、小写字母和数字。',
            confirmPassword: "确认密码",
        }
    },
    // 注册页面
    register: {
        mobileRegister: '手机注册',
        emailRegister: '邮箱注册',
        enroll: '手机号已注册',
        enrollEmail: '邮箱已注册',
        search: '搜索',
        title: '手机账号和邮箱账号互相独立，请注意您的账号类型。',
        needRead: {
            agree: '我已阅读并同意',
            privacy: '《用户隐私协议》',
        },
        register: '注册',
        check: '勾选“用户隐私协议”',
        newUser: '新用户注册',
        form: {
            labelList: ['姓名', '* 性别', '* 身高', '* 年龄'],
        },
        placeholder: ['请输入姓名', '请输入你的身高', '请输入你的年龄'],
        unit: ['公制', '英制', '岁'],
        sexOptions: {
            male: '男',
            female: '女',
        },
        btn: '提交',
        rules: {
            metricheight: ['允许测量的身高范围是 2ft3in-6ft7in，请重新输入'],
            heightMsg: ['允许测量的身高范围是 70-200cm，请重新输入'],
            ageMsg: ['允许测量的年龄范围 10-99岁，请重新输入'],
            agePointMsg: ['填写了非整数：年龄只能为整数，请填写正确的年龄'],
        },
        success: '注册成功',
        userMessage: '请输入用户名',
        verify: '请输入4位数的验证码',
        errorMsg: '允许输入的身高格式不支持小数，请重新输入',
        abnormalMsg: '注册异常',
    },
    // 模型合成
    model: {
        loading: '模型生成中，请您稍候',
        modelLoading: '模型加载中...',
        tabPane: [
            {
                title: '深度实感技术',
                msg: '通过三维扫描准确采集身体局部细节，保证围度测量的毫米级精度，让健身后的体型变化清晰呈现。',
            },
            {
                title: 'BDA 体成分算法',
                msg: '维塑采用最前沿的人体成分测算体系--BDA 算法确定人体成分，这项基于身体体积的测算方式将更精确地评估肥胖带来的健康风险。',
            },
            {
                title: '误差产生因素',
                msg: '宽松的着装、身上的异物等都会导致不同程度的误差，如您想获取最精确的数据，请您穿着紧身衣或减少身上的衣物。',
            },
            {
                title: '报告入口',
                msg: '建议收藏网页，方便日后查看报告。稍后您也可将此报告发送至邮箱保存。',
            },
        ],
        collect: ['请收藏本网页，', '方便日后查看报告。'],
        know: '我知道了',
        reminder: [
            '温馨提示',
            '您的三维模型已生成，可尝试左右滑动来旋转模型。',
            '您的报告已生成，可点击“查看报告”按钮查看。',
            '您的体态评估未成功，请查看体成分报告。',
            '您的身体成分未成功，请查看体态评估报告。',
            '您的测量未成功，请到设备端重新体验哦~',
            '身体成分未成功，请到设备端重新体验哦~',
            '体态评估未成功，请到设备端重新体验哦~',
        ],
        btn: {
            viewReport: '查看报告',
            bodyReport: '查看体成分报告',
            postureReport: '查看体态报告',
            lastReport: '查看上次报告',
            loading: '加载中...',
        },
    },
    // 报告
    report: {
        btn: {
            download: '下载报告',
            send: '发送报告',
            report: '报告',
        },
        reportSuccess: '报告生成成功',
        tabPane: {
            labelList: ['测量时间', '测量项目', '身体成分', '体态评估', '肩部功能'],
        },
        sendEmail: '发送至邮箱:',
        requiredMsg: '请输入你的邮箱地址',
        patternMsg: '请输入正确的邮箱',
        send: '发送',
        download: '下载报告',
        sendReport: '发送报告',
    },
    // 侧边栏
    sidebar: {
        successMsg: {
            sex: '性别修改成功',
            height: '身高修改成功',
            age: '年龄修改成功',
        },
        logOut: '退出登录',
        personal: '个人中心',
        cut: '切换单位/语言',
        changeSort: '切换产品报告',
        setPassword: '设置/修改密码',
        describe: '* 切换产品型号后，将为您展示对应产品型号下的所有测量报告。',
        describeBtn: '产品型号切换后，将为您跳转到该产品型号下的最新测量报告。',
        descReport: '产品报告切换成功',
        unit: ['公制 (kg, cm)', '英制 (ft, in, lb)'],
        read: {
            reading: '阅读',
            privacy: '《用户隐私协议》',
        },
        setting: '设置',
        form: {
            labelList: ['手机号码', '单位', '语言', '昵称', '性别', '身高', '年龄'],
        },
        placeholder: ['修改昵称', '性别修改', '身高修改', '年龄修改'],
        btn: {
            cancelBtn: '取消',
            accomplishBtn: '完成',
            confirmBtn: '确定',
        },
        dialog: [
            '性别是体成分测量的重要依据之一，修改后测量的数据会与以往数据出现差异，请确认是否修改？',
            '身高是体成分测量的重要依据之一，修改后测量的数据会与以往数据出现差异，请确认是否修改？',
            '年龄是体成分测量的重要依据之一，修改后测量的数据会与以往数据出现差异，请确认是否修改？',
        ],
        rules: {
            metricheight: ['请填写范围内身高（2ft3in-6ft7in）'],
            heightMsg: ['请填写范围内身高（70-200cm）'],
            ageMsg: ['请填写范围内年龄（10-99岁）', '填写了非整数：年龄只能为整数，请填写正确的年龄'],
        },
        heightMsg: '请重新选择身高',
        ageMsg: '请重新选择年龄',
        ModelBinding: '好像有人捷足先登扫码了呢，请重新测量吧！',
        ReportEmpty: {
            title: '您还没有相关检测报告',
            desc: '找到 Visbody 3D 体测精灵，进行一次测量吧！',
        },
    },
    // 无测量报告
    modelBinding: {
        desc: '好像有人捷足先登扫码了呢，请重新测量吧！',
    },
    // 扫码模型已被绑定
    reportEmpty: {
        title: '您还没有相关检测报告',
        desc: '找到 Visbody 3D 体测精灵，进行一次测量吧!',
        descS30: '找到 Visbody-S30 3D 体测精灵，进行一次测量吧！',
        descM30: '找到 Visbody-M30 3D 体测精灵，进行一次测量吧！',
    },
    // 用户信息
    userInfo: {
        height: '身高:',
        age: '年龄:',
        unit: '岁',
        weight: '体重',
    },
    // 体成分
    mass: {
        title: '体成分评估总览',
        titles: '身体成分',
        springFrame: '',
        massFrame: '暂无当天测量数据',
        contrast: '选一份报告对比',
        contrastReport: '选择一份对比报告',
        noRecord: '无记录',
        score: '分',
        status: '您目前的体成分状况',
        WT: '体重',
        PBF: '体脂率',
        endSymbol: '。',
        // 测量相关
        measure: {
            value: '测量值',
            standardRange: '标准范围',
            comparedWithLastLime: '对比上次',
            comparedNet: '对比上次',
            current: '本次测量分数',
            WT: '体重',
            FFM: '去脂体重',
            BFM: '体脂肪',
            LM: '肌肉量',
            TBW: '总水分',
            SM: '骨骼肌',
            PROTEIN: '蛋白质',
            TM: '无机盐',
            BMR: '基础代谢',
            WHR: '腰臀比',
            BMI: 'BMI',
            PBF: '体脂率',
            VFG: '内脏脂肪等级',
            unit: 'kg',
            BMRUnit: 'kcal/d',
        },
        // 定义解读
        explain: [
            {
                title: '体重',
                msg: '体重是身体水分、蛋白质、无机盐和体脂肪的总和。',
            },
            {
                title: '去脂体重',
                msg: '去脂体重是体重扣除脂肪的部分。',
            },
            {
                title: '体脂肪',
                msg: '体脂肪是皮下脂肪，内脏脂肪和肌肉之间脂肪的总和。',
            },
            {
                title: '肌肉量',
                msg: '肌肉量是人体的瘦组织群，包括了骨骼肌、平滑肌和心肌。',
            },
            {
                title: '总水分',
                msg: '身体水分在人体成分中占的含量最多，占体重的50%~70%，身体水分分布于人体细胞和体液中，其中大部分存在于肌肉细胞中。',
            },
            {
                title: '骨骼肌',
                msg: '骨骼肌又称横纹肌，附着在骨骼上的肌肉，肌肉中的一种，此处计算的是骨骼肌的含量。',
            },
            {
                title: '蛋白质',
                msg: '蛋白质是含氮的固态物质，存在于人体所有细胞内，参与细胞构成。是肌肉量的主要成分。',
            },
            {
                title: '无机盐',
                msg: '人体是由有机物、无机物和水组成的，人体里的无机物叫无机盐，无机盐大约占人体重量的5%。',
            },
            {
                title: '基础代谢',
                msg: '基础代谢指人体在清醒而安静的状况下，不受运动、食物、神经紧张、外界温度变化等影响时一天消耗的总能量。',
            },
            {
                title: '腰臀比',
                msg: '腰臀比是腰围和臀围的比值，是判定中心性肥胖的重要指标。',
            },
            {
                title: 'BMI',
                msg: 'BMI主要用于评估外观肥胖度，国际上常作为衡量人体胖瘦程度的标准。',
            },
            {
                title: '体脂率',
                msg: '体脂肪率是指体脂肪占体重的比率。',
            },
            {
                title: '内脏脂肪等级',
                msg: '内脏脂肪等级是评价是否属于隐性肥胖的重要指标。',
            },
            {
                title: '细胞内液',
                msg: '细胞内液：是肌体细胞总体所含的体液，是原生质的基本组成部分。',
            },
            {
                title: '细胞外液',
                msg: '细胞外液：通常指为与细胞外的体液，包括血浆以及介于血管和组织细胞之间的组织间液。',
            },
            {
                title: '代谢年龄',
                msg: '代谢年龄是指身体及其健康相关的代谢功能的表现',
            },
        ],
        // 节段
        segment: {
            fat: '节段脂肪',
            muscle: '节段肌肉',
            right: '右',
            left: '左',
            standard: '标准',
            lowStandard: '低标准',
            superStandard: '高标准',
            peel: '(去皮)',
        },
    },
    // 体态
    shape: {
        title: '体态评估总览',
        titles: '体态评估',
        status: '您目前的体态状况',
        suggest: '建议',
        possibility: '，存在',
        models: {
            front: '正面',
            left: '左侧面',
            right: '右侧面',
            top: '背面',
            low: '偏低',
            high: '偏高',
            normal: '正常',
        },
        item: {
            deviate: '测量值',
            result: '说明',
            normal: '正常',
            abnormal: '异常',
			keAbnormal: '可能异常',
            head: '头前引',
            headSlant: '头侧歪',
            roundShoulderLeft: '左圆肩',
            roundShoulderRight: '右圆肩',
            highLowShoudler: '高低肩',
            pelvis: '骨盆前/后移',
            leftKneeCheck: '左膝评估',
            rightKneeCheck: '右膝评估',
            leg: '腿型',
            leftLeg: '左腿：',
            rightLeg: '右腿：',
        },
        // 定义解读
        explain: [
            {
                title: '头前引',
                msg: '左侧面耳朵点与脖子中心连线和侧面中位线的夹角',
            },
            {
                title: '头侧歪',
                msg: '正面头部中点与脖子中心连线和正面中位线的夹角',
            },
            {
                title: '左圆肩',
                msg: '背部左侧最高点连线和肩部切线的夹角',
            },
            {
                title: '右圆肩',
                msg: '背部右侧最高点连线和肩部切线的夹角',
            },
            {
                title: '高低肩',
                msg: '背面左右肩峰点垂直方向的距离',
            },
            {
                title: '骨盆前/后移',
                msg: '左侧面脖子中心点、髋骨点、脚踝三点连线的夹角',
            },
            {
                title: '左膝评估',
                msg: '左侧面髖骨点、膝关节、脚踝点三点连线的夹角',
            },
            {
                title: '右膝评估',
                msg: '右侧面髖骨点、膝关节、脚踝点三点连线的夹角',
            },
            {
                title: '腿型',
                msg: '左右腿正面髋骨骼点、膝关节、脚踝点连线的夹角',
            },
        ],
        exception: [
            {
                title: '偏左',
                abnormal: '',
            },
            {
                title: '偏右',
                abnormal: '',
            },
            {
                title: '左高',
                abnormal: '',
            },
            {
                title: '右高',
                abnormal: '',
            },
            {
                title: '骨盆后移',
                abnormal: '',
            },
            {
                title: '骨盆前移',
                abnormal: '',
            },
            {
                title: '左膝超伸',
                abnormal: '',
            },
            {
                title: '左膝前屈',
                abnormal: '',
            },
            {
                title: '右膝超伸',
                abnormal: '',
            },
            {
                title: '右膝前屈',
                abnormal: '',
            },
            {
                title: 'K型腿',
                abnormal: '',
            },
            {
                title: 'D型腿',
                abnormal: '',
            },
            {
                title: 'X型腿',
                abnormal: '',
            },
            {
                title: 'O型腿',
                abnormal: '',
            },
        ],
    },
    newshape: {
        explain: [
            {
                msg: '耳朵与人体重力线之间的垂直距离',
            },
            {
                msg: '左右耳朵的连线与水平线之间的角度',
            },
            {
                msg: '左肩峰位置与重力线的垂直距离',
            },
            {
                msg: '右肩峰位置与重力线的垂直距离',
            },
            {
                msg: '两侧颈根与肩峰连线的差值',
            },
            {
                msg: '髋关节与人体重力线之间的垂直距离',
            },
            {
                msg: '矢状面上，踝关节、左膝关节下1cm和髋关节的夹角',
            },
            {
                msg: '矢状面上，踝关节、右膝关节下1cm和髋关节的夹角',
            },
            {
                msg: '冠状面上，左右髋关节、膝关节以及踝关节之间的夹角',
            },
        ],
        item: {
            pelvis: '骨盆前移',
        },
    },
    // 围度信息
    girth: {
        title: '身体围度',
        present: '(本次)',
        noHistory: '无数据',
        tpl: [
            {
                title: '颈围',
                key: 'neckGirth',
            },
            {
                title: '左上臂围',
                key: 'leftUpperArmGirth',
            },
            {
                title: '右上臂围',
                key: 'rightUpperArmGirth',
            },
            {
                title: '胸围',
                key: 'bustGirth',
            },
            {
                title: '高腰围',
                key: 'waistGirth',
            },
            {
                title: '中腰围',
                key: 'midWaistGirth',
            },
            {
                title: '臀围',
                key: 'hipGirth',
            },
            {
                title: '左大腿围',
                key: 'leftThighGirth',
            },
            {
                title: '左大腿中部围',
                key: 'leftMidThighGirth',
            },
            {
                title: '左大腿最小围',
                key: 'leftMinThighGirth',
            },
            {
                title: '右大腿围',
                key: 'rightThighGirth',
            },
            {
                title: '右大腿中部围',
                key: 'rightMidThighGirth',
            },
            {
                title: '右大腿最小围',
                key: 'rightMinThighGirth',
            },
            {
                title: '左小腿围',
                key: 'leftCalfGirth',
            },
            {
                title: '右小腿围',
                key: 'rightCalfGirth',
            },
            {
                title: '低腰围',
                key: 'lowWaistGirth',
            },
        ],
    },
    // 肩部
    shoulder: {
        title: '肩部功能',
        model: {
            val: '测量值',
            scope: '最大活动位置',
            diff: '对比上次',
        },
        normal: '暂未发现明显异常',
        normalAll: '肩部功能暂未发现明显异常',
        conclusion: '结论',
        analyse: '分析: ',
        suggest: '建议: ',
        cause: '具体原因请找专业人士做进一步筛查。',
    },
    reportTips: {
        title: '如需删除报告，请联系设备管理员。',
        btnMsg: 'OK',
        alaryTips: '该测量报告已被管理员删除，即将跳转下一份报告！',
    },
    saveQr: {
        title: 'Visbody 3D体测报告',
        btnMsg: '长按图片保存到手机',
        tips: '如发现无法保存，请去【设置】打开相应权限',
    },
    share: {
        title: '请复制下方的链接后分享',
        btnMsg: '复制',
    },
    ...zhLocale,
    ...agrZh,
}
